/* Add custom styles for the Q&R page */
.container {
    max-width: 800px;
    margin: auto;
  }
  
  .progress-bar {
    margin-bottom: 20px;
  }
  
  .button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

 /* Custom styles for the question indicator */
  h1 {
    font-size: 2.5rem; /* Larger font size */
    font-weight: bold; /* Bold text */
    color: #333; /* Darker text color */
    margin-bottom: 20px; /* Spacing below the title */
  }
  
  /* Rest of the CSS remains the same */ 
  
  /* Back button styles */
  .back-button {
    background-color: transparent;
    border: none;
    color: #007bff; /* Blue color */
    font-size: 1rem;
    padding: 10px;
    transition: color 0.3s ease;
  }
  
  .back-button:hover {
    color: #0056b3; /* Darker blue on hover */
    text-decoration: none;
  }
  
  /* Center-align text in the Q&R box */
  .qr-box h4,
  .qr-box p {
    text-align: center;
  }
  
  /* Button spacing */
  .button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

/* Custom styles for the Q&R box */
.qr-box {
    border: 1px solid #ddd; /* Light border */
    border-radius: 10px; /* Rounded corners */
    padding: 30px; /* Inner spacing */
    background-color: #f9f9f9; /* Light background */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    margin-bottom: 20px; /* Spacing below the box */
    min-height: 300px; /* Minimum height to make the box larger */
    width: 100%; /* Increase the width to 90% of the parent container */
    max-width: 1200px; /* Optional: Set a maximum width for larger screens */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Space out content and buttons */
  }
  
  /* Back button styles */
  .back-button {
    background-color: transparent;
    border: none;
    color: #007bff; /* Blue color */
    font-size: 1rem;
    padding: 10px;
    transition: color 0.3s ease;
  }
  
  .back-button:hover {
    color: #0056b3; /* Darker blue on hover */
    text-decoration: none;
  }
  
  /* Center-align text in the Q&R box */
  .qr-box h4,
  .qr-box p {
    text-align: center;
  }
  
  /* Button group inside the box */
  .button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  /* Navigation buttons (Next and Previous) */
  .nav-button {
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 5px;
  }
  
  /* Adjust spacing for navigation buttons */
  .nav-button:first-child {
    margin-right: auto; /* Push Previous button to the left */
  }
  
  .nav-button:last-child {
    margin-left: auto; /* Push Next button to the right */
  }

  