/* Custom styles for the summary box */
.summary-box {
    border: 1px solid #ddd; /* Light border */
    border-radius: 10px; /* Rounded corners */
    padding: 30px; /* Inner spacing */
    background-color: #f9f9f9; /* Light background */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    margin-bottom: 20px; /* Spacing below the box */
  }
  
  /* Custom styles for the resources box */
  .resources-box {
    border: 1px solid #ddd; /* Light border */
    border-radius: 10px; /* Rounded corners */
    padding: 20px; /* Inner spacing */
    background-color: #f9f9f9; /* Light background */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    margin-top: 20px; /* Spacing above the box */
  }
  
  .resources-box h3 {
    font-size: 1.5rem; /* Larger font size for the heading */
    font-weight: bold; /* Bold text */
    color: #333; /* Darker text color */
    margin-bottom: 15px; /* Spacing below the heading */
  }
  
  .resources-box ul {
    list-style-type: none; /* Remove bullet points */
    padding-left: 0; /* Remove default padding */
  }
  
  .resources-box li {
    margin-bottom: 10px; /* Spacing between list items */
  }
  
  .resources-box a {
    color: #007bff; /* Blue color for links */
    text-decoration: none; /* Remove underline */
    transition: color 0.3s ease; /* Smooth color transition */
  }
  
  .resources-box a:hover {
    color: #0056b3; /* Darker blue on hover */
    text-decoration: underline; /* Add underline on hover */
  }
  
  /* Back button styles */
  .back-button {
    background-color: transparent;
    border: none;
    color: #007bff; /* Blue color */
    font-size: 1rem;
    padding: 10px;
    transition: color 0.3s ease;
  }
  
  .back-button:hover {
    color: #0056b3; /* Darker blue on hover */
    text-decoration: none;
  }
  
  /* Download PDF button styles */
  .download-pdf-button {
    background-color: #28a745; /* Green color */
    border: none;
    color: white;
    font-size: 1rem;
    padding: 10px 20px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .download-pdf-button:hover {
    background-color: #218838; /* Darker green on hover */
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .summary-box,
    .resources-box {
      padding: 15px; /* Reduce padding on smaller screens */
    }
  
    .resources-box h3 {
      font-size: 1.25rem; /* Smaller font size for the heading */
    }
  }

/* SummaryPage.css */
.summary-box {
  padding: 1rem;
}

.study-material {
  margin-top: 1rem;
}

.study-material-list {
  list-style-type: none; /* Remove default list styling */
  padding-left: 0;
}

.study-material-list li {
  margin-bottom: 0.5rem;
  padding-left: 1rem;
  text-indent: -1rem; /* Align bullet points */
}

.question-container {
  padding: 1.5rem 0; /* Add padding for spacing */
  border-bottom: 1px solid #e0e0e0; /* Add border line between questions */
}

.question-container:last-child {
  border-bottom: none; /* Remove border for the last question */
}

/* QuestionsPage.css and SummaryPage.css */
.qr-box, .summary-box {
  width: 100%;
  max-width: 100%; /* Ensure it doesn't overflow on small screens */
  padding: 1rem; /* Adjust padding for smaller screens */
  margin: 0 auto; /* Center the container */
}

@media (min-width: 768px) {
  .qr-box, .summary-box {
    max-width: 800px; /* Wider container for larger screens */
    padding: 1.5rem; /* More padding for larger screens */
  }
}