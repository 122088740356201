.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Mobile-specific styles */
@media (max-width: 767.98px) {
  .display-4 {
    font-size: 2rem; /* Smaller font size for mobile */
  }

  .lead {
    font-size: 1rem; /* Smaller font size for mobile */
  }

  .form-control {
    font-size: 0.9rem; /* Smaller font size for textarea */
  }

  .btn {
    font-size: 1rem; /* Smaller font size for buttons */
    padding: 8px 16px; /* Adjust padding for buttons */
  }

  .modal-dialog {
    margin: 10px; /* Reduce modal margin on mobile */
  }

  .modal-body {
    padding: 10px; /* Reduce modal body padding on mobile */
  }

  .modal-footer {
    flex-direction: column; /* Stack buttons vertically on mobile */
    gap: 10px; /* Add spacing between buttons */
  }

  .modal-footer .btn {
    width: 100%; /* Full-width buttons on mobile */
  }
}